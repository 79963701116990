<template>
	<div
		data-component="lesson-nav"
		:data-visible="getIsVisible"
	>
		<nav-button
			icon="cross"
		/>
		<h1 data-element="course-title">
			<router-link
				:to="getCourseRoute"
			>
				{{ getCourseTitle }}
			</router-link>
		</h1>
		<p data-element="lesson-title">
			<router-link
				:to="getLessonRoute"
			>
				{{ getLessonTitle }}
			</router-link>
		</p>
		<activities />
	</div>
</template>

<script>

	import Activities   from '@/components/lesson/lessonNav/Activities';
	import NavButton    from '@/components/lesson/lessonNav/NavButton';
	import routeParams  from '@/mixins/routeParams';

	export default {
		components: {
			Activities,
			NavButton
		},
		mixins: [routeParams],
		data: () => ({}),
		computed: {
			getIsVisible () {
				return this.$store.getters['lesson/getLessonNavIsVisible'];
			},
			getCourseTitle () {
				return this.$store.getters['lesson/getCourseTitle'];
			},
			getLessonTitle () {
				return this.$store.getters['lesson/getLessonTitle'];
			}
		},
		watch: {},
		created () {
			this.setLesson();
		},
		methods: {
			toggleIsVisible () {
				this.$store.commit('lesson/toggleLessonNavIsVisible');
			},
			setLesson () {}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='lesson-nav'] {
		overflow-y:auto;
		position:fixed;
		z-index:7;
		overflow-y:auto;
		top:0;
		left:100vw;
		width:320px;
		height:100vh;
		height:-webkit-fill-available;
		padding:rem(16);
		background-color:$c-white;
		transition:transform 0.6s ease;
		box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.72);
		&[data-visible] {
			transform:translate3d(-100%,0,0);
		}
		[data-component='nav-button'] {
			position:absolute;
			top:rem(12);
			right:rem(12);
		}
		[data-element='course-title'] {
			margin:rem(32) 0 0;
			a {
				font-family:'Montserrat', sans-serif;
				font-size:rem(16);
				font-weight:700;
				color:$c-brand-blue;
				&:hover {
					text-decoration:underline;
				}
			}
		}
		[data-element='lesson-title'] {
			margin:rem(2) 0 0;
			a {
				font-family:'Montserrat', sans-serif;
				font-size:rem(24);
				font-weight:700;
				color:$c-brand-blue;
				&:hover {
					text-decoration:underline;
				}
			}
		}
	}

</style>
