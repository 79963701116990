<template>
	<div
		v-if="getNotification"
		data-component="notification"
	>
		<div data-element="wrapper">
			<p data-element="text">
				{{ getNotification }}
			</p>
			<icon
				icon="cross"
				size="lg"
				@click.native="remove"
			/>
		</div>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			position: {
				type: String,
				default: 'top'
			}
		},
		computed: {
			getNotification () {
				return this.$store.getters['ui/getNotification'];
			}
		},
		methods: {
			remove () {
				this.$store.commit('ui/removeNotification');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='notification'] {
		position:fixed;
		bottom:rem(32);
		z-index:10;
		@include grid;
		[data-element='wrapper'] {
			@include cols(12);
			padding:rem(14) rem(18);
			background-color:$c-white;
			border-radius:8px;
			display:flex;
			align-items:center;
			box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.72);
		}
		[data-element='text'] {
			margin:0;
			font-size:rem(14);
			font-weight:700;
		}
		[data-component='icon'] {
			width:rem(28);
			height:auto;
			margin:auto 0 auto auto;
			cursor:pointer;
			user-select:none;
		}
	}

</style>
