<template>
	<div
		v-if="getActivities"
		data-component="activities"
	>
		<h2 data-element="activities-heading">
			Activities in this lesson
		</h2>
		<activity
			v-for="(activity, index) in getActivities"
			:key="activity.id"
			:index="index"
			:activityId="activity.id"
		/>
	</div>
</template>

<script>

	import Activity     from '@/components/lesson/lessonNav/Activity';
	import routeParams  from '@/mixins/routeParams';

	export default {
		components: {
			Activity
		},
		mixins: [routeParams],
		props: {},
		data: () => ({}),
		computed: {
			getActivities () {
				return this.$store.getters['lesson/getActivities'];
			}
		},
		watch: {},
		created () {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='activities'] {
		[data-element='activities-heading'] {
			margin:rem(28) 0 rem(14);
			font-family:'Montserrat', sans-serif;
			font-size:rem(16);
			font-weight:700;
			color:$c-brand-blue;
		}
		[data-component='activity'] {
			margin-bottom:rem(22);
			&:last-child {
				margin-bottom:0;
			}
		}
	}

</style>
