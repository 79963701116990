<template>
	<div data-component="nav-button">
		<icon
			size="sm"
			:icon="icon"
			colour="white"
			@click.native="toggleLessonNav"
		/>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			icon: {
				type: String,
				default: 'burger'
			}
		},
		methods: {
			toggleLessonNav () {
				this.$store.commit('lesson/toggleLessonNavIsVisible');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='nav-button'] {
		padding:rem(4);
		border-radius:4px;
		background-color:$c-blue;
		user-select:none;
		cursor:pointer;
		[data-component='icon'] {
			width:rem(24); height:auto;
		}
	}
</style>
