<template>
	<router-link
		data-component="activity"
		:data-current-route="getActivityIsCurrentRoute"
		:to="getActivityRoute"
	>
		<div data-element="info">
			<p data-element="type">
				{{ getType }}
			</p>
			<h1 data-element="title">
				<span data-element="activity-number">
					{{ getActivityNumber }}.
				</span>
				{{ getActivity.title }}
			</h1>
		</div>
		<!--
		// TODO: Move activities into store so this can be updated
		//       dynamically as each activity is completed
		<icon
			v-if="context === 'student'"
			data-element="progress-icon"
			:icon="getProgressIcon"
			:colour="getIconColour"
			size="sm"
		/>
		-->
	</router-link>
</template>

<script>

	// import Icon               from '@/components/ui/Icon';
	import routeParams        from '@/mixins/routeParams';
	import { activityTypes }  from '@/consts';

	export default {
		components: {
			// Icon
		},
		mixins: [routeParams],
		props: {
			index: {
				type: Number,
				required: true
			},
			activityId: {
				type: Number,
				required: true
			},
			context: {
				type: String,
				default: 'student'
			}
		},
		data: () => ({}),
		computed: {
			getActivity () {
				return this.$store.getters['lesson/getActivityById']({
					activityId: this.activityId
				});
			},
			getActivityRoute () {
				if (!this.getLessonRoute ||
						!this.getActivityId) {
					return false;
				}
				if (this.getContext === 'teaching' ||
						this.getContext === 'admin') {
					return `${this.getLessonRoute}/activities/${this.activityId}/view`;
				}
				return `${this.getLessonRoute}/activities/${this.activityId}`;
			},
			getType () {
				if (!this.getActivity?.type) {
					return false;
				}
				return activityTypes.find((activityType) => {
					return activityType.value === this.getActivity.type;
				}).text;
			},
			getActivityNumber () {
				return this.index + 1;
			},
			getActivityIsCurrentRoute () {
				return this.activityId === this.getActivityId;
			},
			// getProgressIcon () {
			// 	if (!this.getActivity.isComplete) {
			// 		return 'question';
			// 	}
			// 	if (!this.getActivity.progress) {
			// 		return 'cross-circle';
			// 	}
			// 	return 'check';
			// },
			getIconColour () {
				if (this.getActivityIsCurrentRoute) {
					return 'white';
				}
				return 'blue';
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='activity'] {
		position:relative;
		display:flex;
		align-items:center;
		padding:rem(16) rem(10) rem(16) rem(18);
		border:6px solid $c-brand-blue;
		border-radius:8px;
		&:before {
			content:'';
			position:absolute;
			top:2px; right:2px;
			bottom:2px; left:2px;
			display:block;
			border:2px solid $c-brand-blue;
		}
		[data-element='info'] {
			margin:auto auto auto 0;
			[data-element='type'] {
				margin:0;
				font-size:rem(12);
			}
			[data-element='title'] {
				display:block;
				margin:0 0 0 0;
				font-weight:700;
				[data-element='activity-number'] {
				}
			}
		}
		[data-element='progress-icon'] {
			width:rem(32);
			height:auto;
			margin:auto 0 auto auto;
		}
		&[data-current-route] {
			background:$c-brand-blue;
			[data-element='info'] {
				[data-element='type'] {
					color:$c-brand-white;
				}
				[data-element='title'] {
					color:$c-brand-white;
				}
			}
		}
	}

</style>
