<template>
	<div
		data-component="modal"
		v-show="getAnyModalIsVisible"
	>
		<div
			data-element="wrapper"
			v-show="modalIsVisible"
		>
			<portal-target
				name="modal"
				@change="onModalChange"
			/>
		</div>
		<div
			data-element="wrapper"
			data-stacked-on-top
			v-show="stackedOnTopModalIsVisible"
		>
			<portal-target
				name="stackedOnTopModal"
				@change="onStackedOnTopModalChange"
			/>
		</div>
		<div
			data-element="wrapper"
			data-fullscreen
			v-show="fullscreenModalIsVisible"
		>
			<portal-target
				name="fullscreenModal"
				@change="onFullscreenModalChange"
			/>
		</div>
	</div>
</template>

<script>

	export default {
		components: {},
		mixins: [],
		props: {},
		data: () => ({
			modalIsVisible: false,
			stackedOnTopModalIsVisible: false,
			fullscreenModalIsVisible: false
		}),
		computed: {
			getAnyModalIsVisible () {
				if (!this.modalIsVisible &&
						!this.fullscreenModalIsVisible) {
					return false;
				}
				return true;
			}
		},
		watch: {},
		mounted () {},
		methods: {
			onModalChange (newVal, oldVal) {
				this.modalIsVisible = newVal;
				if (!newVal) {
					this.$store.commit('ui/setModalIsHidden');
					return;
				}
				this.$store.commit('ui/setModalIsVisible');
			},
			onStackedOnTopModalChange (newVal, oldVal) {
				this.stackedOnTopModalIsVisible = newVal;
				if (!newVal && !this.modalIsVisible && !this.fullscreenModalIsVisible) {
					this.$store.commit('ui/setModalIsHidden');
					return;
				}
				this.$store.commit('ui/setModalIsVisible');
			},
			onFullscreenModalChange (newVal, oldVal) {
				this.fullscreenModalIsVisible = newVal;
				if (!newVal) {
					this.$store.commit('ui/setModalIsHidden');
					return;
				}
				this.$store.commit('ui/setModalIsVisible');
			},
			close () {
				this.$emit('close');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='modal'] {
		width:100%;
		[data-element='wrapper'] {
			overflow-y:auto;
			position:fixed;
			z-index:5;
			top:0; right:0;
			bottom:0; left:0;
			display:flex;
			align-items:flex-start;
			justify-content:center;
			width:100vw;
			height:100vh;
			height:-webkit-fill-available;
			padding:rem(64) 0;
			background:rgba(0,0,0,0.2);
			::v-deep .vue-portal-target {
				@include grid-container;
				width:100%;
			}
			&[data-stacked-on-top] {
				z-index:6;
			}
			&[data-fullscreen] {
				outline:10px solid red;
				padding:0;
				::v-deep .vue-portal-target {
					width:100%;
					max-width:none;
					margin:0;
				}
			}
		}
	}

</style>
